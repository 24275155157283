import React, { Component } from "react";

export default class Logos extends Component {
  render() {
    return (
      <div className="bg-gray-900">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-base leading-6 font-semibold text-gray-400 tracking-wider">
            {this.props.title && this.props.title}
          </p>
          <div className="mt-6 grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8 flex flex-wrap items-center justify-center">
            {this.props.items?.map((l, i) => (
              <div
                className="col-span-1 flex justify-center py-8 px-8 partner-logo"
                key={i}
              >
                <img
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease-in-out"
                  data-sal-duration="500"
                  className="max-h-12 "
                  src={l.partner_logo.url}
                  alt={l.partner_logo.alt}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
