import React, { Component } from "react";
import Pattern from "../images/pattern.svg";
import { Date, Link, RichText } from "prismic-reactjs";
import TopBanner from "../components/TopBanner";

const Hero = (props) => {
  return (
    <>
      <header className=" mx-auto max-w-screen-xl px-4  sm:px-6 ">
        {/* {props.displayBanner && <TopBanner />} */}
        <div className="text-center pt-16 ">
          <h1
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-in-out"
            data-sal-duration="500"
            className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl pt-16 md:pt-16 lg:pt-20 xl:pt-24"
          >
            {props.title}
          </h1>

          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-in-out"
            data-sal-duration="500"
            className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl "
          >
            <RichText render={props.description} />
          </div>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="absolute absolute-pattern">
              <img src={Pattern} alt="pattern zenops" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Hero;
