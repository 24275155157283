import React from "react";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";

const Feature = (props) => {
  return (
    <div className="columns flex flex-wrap items-center max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 features mb-8">
      <div className="w-full md:w-1/2 py-8 px-6 flex flex-col justify-center  ">
        <div
          className="flex items-center font-bold  text-primary-900 label"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-in-out"
          data-sal-duration="500"
        >
          {props.label}
        </div>
        <h2
          className="mt-6 text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 "
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-in-out"
          data-sal-duration="500"
        >
          {props.title}
        </h2>
        <div
          className="mt-6 text-lg leading-7 text-gray-500 prose"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-in-out"
          data-sal-duration="500"
        >
          <RichText render={props.desc}/> 
        </div>
        {props.content && (
          <div
            className="flex items-center font-bold text-primary-900 underline mt-2"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-in-out"
            data-sal-duration="500"
          >
            <a href="#form">
              Téléchargez le contenu !
            </a>
          </div>
        )}
      </div>

      <div className="w-full md:w-1/2 p-6 md:block text-center" data-sal="fade">
        {props.img && (
          <Img fluid={props.img.fluid} alt="feature image zenops" />
        )}
        {props.video && (
          <iframe
            src={props.video}
            frameBorder="0"
            width="100%"
            height="400"
            allow="autoplay; fullscreen"
            allowFullScreen
            className="mx-auto max-w-screen-xl "
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default Feature;
