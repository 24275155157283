import React, { Component } from "react";

export default class Logos extends Component {
  render() {
    return (
      <div className="bg-gray-900">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-3xl leading-6 font-semibold text-gray-300 tracking-wider">
            {this.props.title && this.props.title}
          </p>
          <div className="mt-6 flex flex-wrap items-center justify-center">
            <div className="max-w-screen-xl py-8  lg:px-16 xl:px-24 ">
              <img
                className="max-w-full"
                src={this.props.img[0].partner_logo.url}
                alt="large image zenops team"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
